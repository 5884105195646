import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import type { QuarterContentProps } from './';

export const QuarterContent = forwardRef<HTMLDivElement, QuarterContentProps>(({ quarter, shortQuarter }, ref) => {
  const { formatMessage } = useIntl();

  const quarterBreakdown = formatMessage({ id: `common.form.datepicker.quarters.q${quarter}` });

  return (
    <div ref={ref} className="flex flex-col gap-1">
      <span className="label-100 text-neutral-1000">{shortQuarter}</span>
      <span className="label-400 text-neutral-600">{quarterBreakdown}</span>
    </div>
  );
});
