import z from 'zod';

export const ALLOCATION_STATUSES = z.enum(['pending', 'executed', 'draft', 'pending_cancellation']);

export enum ALLOCATION_MODE {
  AS_PRODUCED = 'AS_PRODUCED',
  AS_CONSUMED = 'AS_CONSUMED',
}

export enum ALLOCATION_RESULT_TYPE {
  CURRENT = 'current',
  DRAFT = 'draft',
}

export enum ENERGY_UNIT {
  kwh = 'kWh',
  mwh = 'MWh',
  gwh = 'GWh',
  twh = 'TWh',
}

export const carbonContentEnum = z.enum(['g', 'kg', 't', 'kt', 'Mt', 'Gt']);

export const CREATE_ALLOCATION_MODE = z.enum(['AS_PRODUCED_SET_VALUES', 'AS_CONSUMED_MAX_FILL']);
export type CreateAllocationModeType = z.infer<typeof CREATE_ALLOCATION_MODE>;

export const intervalEnum = z.enum(['5min', '15min', '30min', 'H', 'D', 'MS', 'QS', 'Y', 'all']);
export type Interval = z.infer<typeof intervalEnum>;

export const timeAttributeEnum = z.enum(['MINUTE', 'HOUR', 'DAY_OF_WEEK', 'MONTH', 'QUARTER', 'YEAR']);
export type TimeAttribute = z.infer<typeof timeAttributeEnum>;

export const matchingResolutionEnumLower = z.enum([
  'five_minute',
  'fifteen_minute',
  'thirty_minute',
  'hourly',
  'daily',
  'monthly',
  'quarterly',
  'yearly',
  'yearly_uk_compliance_period',
  'whole_period',
]);
export type MatchingResolutionLower = z.infer<typeof matchingResolutionEnumLower>;

export const matchingResolutionEnum = z.enum([
  'FIVE_MINUTE',
  'FIFTEEN_MINUTE',
  'THIRTY_MINUTE',
  'HOURLY',
  'DAILY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
  'YEARLY_UK_COMPLIANCE_PERIOD',
  'WHOLE_PERIOD',
]);
export type MatchingResolution = z.infer<typeof matchingResolutionEnum>;

export const dateFormatEnum = z.enum([
  'HH:mm d MMM',
  'HH:mm',
  'MMM y',
  'MMM yy',
  'MMM',
  'P',
  'Pp',
  'QQQ yy',
  'ccc',
  'd MMM yy',
  'd MMM yyyy',
  'd MMM',
  'd MMM, HH:mm',
  'EEE d MMM',
  'EEE d MMM, HH:mm',
  'y',
  'yyyy-MM-dd',
  'yyyy-MM',
]);
export type DateFormat = z.infer<typeof dateFormatEnum>;

export const durationValues = ['ALL', 'PT5M', 'PT15M', 'PT30M', 'PT1H', 'P1D', 'P1M', 'P3M', 'P1Y'] as const;
export const durationEnum = z.enum(durationValues);
export type Durations = z.infer<typeof durationEnum>;

export const PPA_TRIGGER_TYPES = z.enum(['AUTO_PAY_AS_PRODUCED', 'AUTO_PAY_AS_CONSUMED', 'MANUAL']);

export const countryEnum = z.enum([
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'AN',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'SH',
  'KN',
  'LC',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'Unknown',
]);

export const technologyEnum = z.enum([
  'AnyRenewable',
  'Battery',
  'Biodegradable',
  'Biogas',
  'Biomass',
  'Coal',
  'Gas',
  'GeoThermal',
  'Hydro',
  'LandfillGas',
  'Marine',
  'Nuclear',
  'OffshoreWind',
  'OnshoreWind',
  'SewageGas',
  'Solar',
  'Unknown',
  'Wind',
  'AnyFossil',
]);
