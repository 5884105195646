import { isAfter, isBefore } from 'date-fns';

export const DATE_FORMATS = {
  DAY: 'd',
  DAY_SHORT_MONTH: 'd MMM',
  DAY_SHORT_MONTH_YEAR: 'd MMM yyyy',

  SHORT_DAY_OF_WEEK: 'ccc',

  SHORT_MONTH: 'MMM',
  SHORT_MONTH_YEAR: 'MMM yyyy',
  SHORT_MONTH_SHORT_YEAR: 'MMM yy',
  MONTH_YEAR: 'MMMM yyyy',

  DATE: 'dd/MM/yyyy',

  DATE_TIME: 'dd-MM-yyyy HH:mm:ss',

  DATE_TIME_TIDY: 'dd/MM/yyyy, HH:mm',

  QUARTER: 'QQQ',
  QUARTER_YEAR: 'QQQ yyyy',

  YEAR: 'yyyy',

  LOCALIZED: 'P',

  ISO_DATE: 'yyyy-MM-dd',
} as const;

export const DATE_TIME_FORMATS = {
  LOCALIZED: 'Pp',
} as const;

// returns earliest or latest date from a list of dates
export function getFirstOrLastDate(list: Array<string>, earliest = true): string {
  if (!list.length) {
    return '';
  }

  return list.reduce((acc, date) => {
    if (!acc) {
      return date;
    }

    return earliest ? (isBefore(date, acc) ? date : acc) : isAfter(date, acc) ? date : acc;
  }, '');
}
