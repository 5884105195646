import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import type * as Dialogs from '~/components/dialogs';

export type DialogKeys = keyof typeof Dialogs;

/** Return data from any API call */
// biome-ignore lint/suspicious/noExplicitAny: API response can be vary
export type StateData = Record<string, any> | null;

export interface DialogState {
  /** The dialog's file name will act as the ID */
  id: DialogKeys;
  /** Determines if the Dialog is open */
  open: boolean;
  /** The props to be passed to the Dialog */
  props: Record<string, unknown>;
}

export const dialogFamilyAtom = atomFamily(
  (id: DialogKeys) => {
    return atom<DialogState>({ id, open: false, props: {} });
  },
  (a, b) => a === b,
);

export const dialogKeysAtom = atom<DialogKeys[]>([]);
export const dialogStateDataAtom = atom<StateData>(null);
