import ReactDatePicker from 'react-datepicker';

import { DatePickerHeader, type DatePickerProps, QuarterContent } from './';

export function DatePicker<T extends boolean | undefined = undefined>({
  showMonthYearPicker = false,
  showQuarterYearPicker = false,
  showYearPicker = false,
  calendarStartDay = 1,
  inline = true,
  ...props
}: DatePickerProps<T>) {
  return (
    <ReactDatePicker
      inline
      calendarStartDay={calendarStartDay}
      renderCustomHeader={(headerProps) => (
        <DatePickerHeader
          showMonthYearPicker={showMonthYearPicker || showQuarterYearPicker}
          showYearPicker={showYearPicker}
          {...headerProps}
        />
      )}
      showMonthYearPicker={showMonthYearPicker}
      showQuarterYearPicker={showQuarterYearPicker}
      showYearPicker={showYearPicker}
      renderQuarterContent={(quarter, shortQuarterText) => (
        <QuarterContent quarter={quarter} shortQuarter={shortQuarterText} />
      )}
      {...props}
    />
  );
}
