import { type ChangeEvent, forwardRef, useMemo, useState } from 'react';
import { contextFactory } from '~/utils/contextFactory';
import type { BaseSelectContextProps, BaseSelectProps } from './BaseSelect.props';

// This component is a base select component that can be used to create custom select components.
// It shouldn't be used directly, but instead to compose other select components.

export const [BaseSelectContext, useBaseSelectContext] = contextFactory<BaseSelectContextProps>();

export const BaseSelect = forwardRef<HTMLDivElement, BaseSelectProps>(
  (
    {
      applyLabel = 'Apply',
      backLabel = 'Back',
      children,
      disableFiltering,
      indeterminateItems,
      items = [],
      loading,
      noDataLabel = 'No data',
      resetLabel = 'Reset',
      searchPlaceholder,
      selectedItems = [],
      selectedLimit,
      width,
      onSelectChange,
      toggleOpen,
    },
    forwardedRef,
  ) => {
    const [searchTerm, setSearchTerm] = useState('');

    function resetSearchTerm() {
      setSearchTerm('');
    }

    function handleSearchChange(event?: ChangeEvent<HTMLInputElement>) {
      setSearchTerm(event ? event.target.value : '');
    }

    const filteredItems = useMemo(() => {
      if (disableFiltering) {
        return items;
      }
      return items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [items, disableFiltering, searchTerm]);

    return (
      <div ref={forwardedRef}>
        <BaseSelectContext.Provider
          value={{
            applyLabel,
            backLabel,
            indeterminateItems,
            items: filteredItems,
            loading,
            noDataLabel,
            resetLabel,
            searchPlaceholder,
            searchTerm,
            selectedItems,
            selectedLimit,
            width: width,
            handleSearchChange,
            handleSelectChange: onSelectChange,
            resetSearchTerm,
            toggleOpen,
          }}
        >
          {children}
        </BaseSelectContext.Provider>
      </div>
    );
  },
);
