import { forwardRef } from 'react';

import { Icon } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { IconBadgeProps } from './IconBadge.types';

export const IconBadge = forwardRef<HTMLSpanElement, IconBadgeProps>(
  ({ icon = 'DashboardIcon', appearance = 'info', size = 'large', className }, forwardedRef) => {
    const classes = classNames(
      'flex items-center justify-center rounded-full',
      {
        'bg-neutral-100': appearance === 'info',
        'bg-red-100': appearance === 'danger',
        'bg-green-100': appearance === 'success',
        'mb-4 h-[80px] w-[80px]': size === 'large',
        'mb-2 h-[40px] w-[40px]': size === 'medium',
        'h-[32px] w-[32px]': size === 'small',
      },
      className,
    );

    const iconClasses = classNames({
      'text-neutral-500': appearance === 'info',
      'text-red-700': appearance === 'danger',
      'text-green-700': appearance === 'success',
      'h-[35px] w-[35px]': size === 'large',
      'h-[20px] w-[20px]': size === 'medium',
      'h-[16px] w-[16px]': size === 'small',
    });

    return (
      <span role="img" ref={forwardedRef} className={classes}>
        <Icon icon={icon} className={iconClasses} />
      </span>
    );
  },
);
