import { FormattedMessage } from 'react-intl';
import { Button } from '~/components/granular';
import type { SelectListApplyButtonsProps } from './SelectListApplyButtons.props';

export function SelectListApplyButtons({ onApplySelection, onResetSelection }: SelectListApplyButtonsProps) {
  return (
    <div className="flex justify-end gap-2 border-neutral-200 border-t px-4 py-3">
      <Button appearance="secondary" size="small" onClick={onResetSelection}>
        <FormattedMessage id="common.reset" />
      </Button>

      <Button size="small" className="" onClick={onApplySelection}>
        <FormattedMessage id="common.apply" />
      </Button>
    </div>
  );
}
