import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import { type ButtonAction, ButtonActions, Icon, IconBadge, Link, Markdown } from '~/components/granular';
import { classNames } from '~/utils/styles';
import { GradientScroller } from '../gradient-scroller/GradientScroller';
import type { DialogProps } from './Dialog.types';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions,
      children,
      className = '',
      icon,
      iconAppearance,
      linkAction,
      markdown,
      open,
      preventOutsideClickClose,
      title,
      trigger,
      width = 'medium',
      onOpenChange,
      ...props
    },
    forwardedRef,
  ) => (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger ? <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger> : null}
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="fixed inset-0 z-20 flex bg-black bg-opacity-50 data-[state=open]:animate-overlayShow">
          <RadixDialog.Content
            ref={forwardedRef}
            className={classNames(
              'm-auto flex max-h-[85vh] w-full flex-col overflow-hidden rounded-[6px] bg-white shadow-xl focus:outline-none data-[state=open]:animate-contentShow',
              {
                'max-w-[300px]': width === 'small',
                'max-w-[480px]': width === 'medium',
                'max-w-[920px]': width === 'large',
                'max-w-[calc(100%-10rem)]': width === 'full',
              },
              className,
            )}
            onPointerDownOutside={(event) => preventOutsideClickClose && event.preventDefault()}
            aria-describedby={undefined}
            {...props}
          >
            <div className="flex items-center border-neutral-200 border-b border-solid px-6 py-4">
              {icon && <IconBadge icon={icon} appearance={iconAppearance} size="small" className="mr-3" />}
              <RadixDialog.Title className="title-200 text-neutral-1000" data-testid="dialog-heading">
                {title}
              </RadixDialog.Title>
              <RadixDialog.Close asChild className="ml-auto">
                <button type="button" aria-label="Close">
                  <Icon icon="CloseIcon" width={24} height={24} className="text-neutral-1000" />
                </button>
              </RadixDialog.Close>
            </div>

            <GradientScroller className="flex min-h-0 shrink-1 flex-col">
              <div className="body-200-light px-6 py-5 text-neutral-800">
                {markdown && <Markdown markdown={markdown} />}
                {children}
              </div>

              {(actions || linkAction) && (
                <div className="flex justify-end px-6 pt-5 pb-6" data-testid="dialog-actions">
                  {linkAction ? (
                    <Link
                      key={linkAction.id}
                      leftIcon={linkAction.leftIcon}
                      rightIcon={linkAction.rightIcon}
                      href={linkAction.href}
                      target={linkAction.target}
                    >
                      {linkAction.children}
                    </Link>
                  ) : (
                    <ButtonActions actions={actions as Array<ButtonAction>} />
                  )}
                </div>
              )}
            </GradientScroller>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  ),
);
