import { format } from 'date-fns';
import { forwardRef, useMemo, useState } from 'react';
import { DatePicker, Input, Popover } from '~/components/granular';

import type { DatePickerInputProps } from './DatePickerInput.props';

export const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
  (
    { id, hasError, name, placeholder, selectsRange, showMonthYearPicker, dateFormat = 'dd/MM/yyyy', onChange },
    forwardedRef,
  ) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | [Date | null, Date | null] | null>(null);

    const startDate = useMemo(() => {
      if (Array.isArray(selectedDate)) {
        return selectedDate[0];
      }
      return selectedDate;
    }, [selectedDate]);

    const endDate = useMemo(() => {
      if (Array.isArray(selectedDate)) {
        return selectedDate[1];
      }
      return null;
    }, [selectedDate]);

    const inputValue = useMemo(() => {
      if (!selectedDate) {
        return '';
      }

      if (Array.isArray(selectedDate)) {
        const startDate = selectedDate[0] ? format(selectedDate[0], dateFormat) : '';
        const endDate = selectedDate[1] ? format(selectedDate[1], dateFormat) : '';
        return `${startDate} - ${endDate}`;
      }

      return format(selectedDate, dateFormat);
    }, [selectedDate, dateFormat]);

    function handleOnChange(date: Date | [Date | null, Date | null] | null) {
      setSelectedDate(date);
      onChange?.(date);

      if (selectsRange && Array.isArray(date) && date.some((d) => d === null)) {
        return;
      }

      setPopoverOpen(false);
    }

    return (
      <Popover
        ref={forwardedRef}
        open={popoverOpen}
        onTriggerClick={(open) => setPopoverOpen(open)}
        trigger={
          <Input
            id={id}
            hasError={hasError}
            name={name}
            type="text"
            readOnly
            value={inputValue}
            autoComplete="off"
            placeholder={placeholder}
          />
        }
      >
        <div className="px-4 py-3">
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => handleOnChange(date)}
            selectsRange={selectsRange}
            showMonthYearPicker={showMonthYearPicker}
          />
        </div>
      </Popover>
    );
  },
);
