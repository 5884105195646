import { Transition } from '@headlessui/react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { type ElementType, forwardRef, useState } from 'react';

import { useSessionStorage } from 'usehooks-ts';
import { Icon, Link, Popover, Tooltip } from '~/components/granular';
import { usePanelContext } from '~/components/granular/panel';
import { classNames } from '~/utils/styles';

import type {
  NavigationFooterLinkProps,
  NavigationFooterProps,
  NavigationLinkProps,
  NavigationProps,
} from './Navigation.types';

export function Navigation({ navItems, isCollapsed = false, className }: NavigationProps) {
  return (
    <ScrollArea.Root className="w-full overflow-hidden">
      <ScrollArea.Viewport className="h-full w-full">
        <nav className={classNames('max-w-[200px]', className)}>
          {navItems.map((item) => (
            <div key={item.id} className="mb-4 overflow-hidden whitespace-nowrap">
              <Transition
                className="overflow-hidden whitespace-nowrap"
                show={!isCollapsed}
                enter="transform transition-all duration-300"
                enterFrom="opacity-0 h-0"
                enterTo="opacity-100 h-[64px]"
                leave="transform duration-300 transition-all ease-in-out "
                leaveFrom="opacity-100 h-[64px]"
                leaveTo="opacity-0 h-0"
              >
                <div className="label-300 px-5 py-2 text-neutral-600">{item.label}</div>
              </Transition>
              {item.links.map((child) => (
                <NavigationLinkWithTooltip
                  key={child.id}
                  icon={child.icon}
                  label={child.label}
                  isCollapsed={isCollapsed}
                  onClick={child.onClick}
                  href={child.href}
                  isActive={child.isActive}
                  to={child.to}
                  link={child.link}
                  links={child.links}
                />
              ))}
            </div>
          ))}
        </nav>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="flex touch-none select-none bg-blackA3 p-0.5 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="before:-translate-x-1/2 before:-translate-y-1/2 relative flex-1 rounded-[10px] bg-mauve10 before:absolute before:top-1/2 before:left-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:content-['']" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}

export const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkProps>(
  (
    { className, link = 'a', links, icon, isActive = false, label, isCollapsed = false, withArrow = false, ...props },
    forwardedRef,
  ) => {
    const NavLink = link as ElementType;

    const { onToggleCollapse } = usePanelContext();

    const childLinks = links?.filter(({ isHidden }) => !isHidden);

    // check if child is active using location.href
    const isAnyLinkActive = !!childLinks?.some((link) => {
      const linkTo = typeof link.to === 'function' ? link.to('') : link.to;
      return window.location.href.includes(link.href || linkTo || '');
    });

    const [linksExpanded, setLinksExpanded] = useSessionStorage<boolean>(
      `navigation/linksExpanded/${label}`,
      isAnyLinkActive,
    );

    // this is to set the initial state of the linksExpanded, as useSessionStorage
    // only initializes it in memory, but not in sessionStorage itself
    if (links?.length && sessionStorage.getItem(`navigation/linksExpanded/${label}`) === null) {
      sessionStorage.setItem(`navigation/linksExpanded/${label}`, isAnyLinkActive.toString());
    }

    function handleItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
      if (links?.length) {
        if (isCollapsed) {
          onToggleCollapse?.();
          setLinksExpanded(true);
        } else {
          setLinksExpanded(!linksExpanded);
        }
      }
      props.onClick?.(event);
    }

    return (
      <div>
        <NavLink
          ref={forwardedRef}
          className={classNames(
            'label-200 flex cursor-pointer items-center gap-2 overflow-hidden text-ellipsis px-5 py-2 text-neutral-1000 hover:bg-neutral-200 aria-current:bg-teal-300 aria-current:text-teal-700 [&_svg]:text-neutral-600 [&_svg]:aria-current:text-teal-700',
            {
              'bg-teal-300 text-teal-700 [&_svg]:text-teal-700':
                isActive || (isAnyLinkActive && (!linksExpanded || isCollapsed)),
            },
            className,
          )}
          onClick={handleItemClick}
          href={props.href}
          to={props.to}
        >
          {icon && (
            <span>
              <Icon icon={icon} width={16} height={16} filled={false} />
            </span>
          )}
          <Transition
            as="span"
            className="flex w-full overflow-hidden text-ellipsis whitespace-nowrap"
            show={!isCollapsed}
            enter="transform transition-opacity duration-300"
            enterFrom="opacity-0 "
            enterTo="opacity-100"
            leave="transform duration-300 transition-opacity ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {label}
            {withArrow && (
              <Icon className="ml-auto text-neutral-600" icon="ArrowRightSIcon" width={16} height={16} filled={true} />
            )}
            {!!childLinks?.length && (
              <Icon
                className="ml-auto text-neutral-600"
                icon={linksExpanded ? 'ArrowUpSIcon' : 'ArrowDownSIcon'}
                width={16}
                height={16}
                filled={true}
              />
            )}
          </Transition>
        </NavLink>

        {!isCollapsed && !!childLinks?.length && (
          <Transition
            className="overflow-hidden whitespace-nowrap"
            show={linksExpanded}
            enter="transform transition-all duration-300"
            enterFrom="opacity-0 h-0"
            enterTo="opacity-100 h-[64px]"
            leave="transform duration-300 transition-all ease-in-out "
            leaveFrom="opacity-100 h-[64px]"
            leaveTo="opacity-0 h-0"
          >
            {links?.map((child) => (
              <NavigationLink
                className="pl-11"
                key={child.id}
                icon={child.icon}
                label={child.label}
                isCollapsed={isCollapsed}
                onClick={child.onClick}
                href={child.href}
                isActive={child.isActive}
                to={child.to}
                link={child.link}
                links={child.links}
              />
            ))}
          </Transition>
        )}
      </div>
    );
  },
);

export const NavigationLinkWithTooltip = forwardRef<HTMLAnchorElement, NavigationLinkProps>(
  ({ link = 'a', links, icon, label, isCollapsed = false, withArrow = false, ...props }, forwardedRef) => {
    return (
      <Tooltip
        openOnHover
        disabled={!isCollapsed}
        direction="right"
        trigger={
          <NavigationLink
            ref={forwardedRef}
            icon={icon}
            label={label}
            isCollapsed={isCollapsed}
            withArrow={withArrow}
            isActive={props.isActive}
            onClick={props.onClick}
            href={props.href}
            to={props.to}
            link={link}
            links={links}
          />
        }
      >
        {label}
      </Tooltip>
    );
  },
);

export function NavigationFooter({ className, navItems, isCollapsed = false }: NavigationFooterProps) {
  return (
    <div className={classNames(className)}>
      {navItems.map((item) => (
        <FooterNavigationLinkWithPopover
          key={item.id}
          icon={item.icon || 'SettingsIcon'}
          label={item.label}
          isCollapsed={isCollapsed}
          links={item.links}
        />
      ))}
    </div>
  );
}

export function FooterNavigationLinkWithPopover({
  icon,
  label,
  links,
  link,
  isCollapsed = false,
}: NavigationFooterLinkProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const linkClassName = 'px-4 py-[10px] hover:bg-neutral-100 hover:no-underline';
  return (
    <Popover
      className="min-w-[120px] p-1"
      open={popoverOpen}
      direction="right"
      trigger={<NavigationLinkWithTooltip icon={icon} label={label} isCollapsed={isCollapsed} link={link} withArrow />}
      onTriggerClick={(open) => setPopoverOpen(open)}
      onOpenAutoFocus={(e) => e?.preventDefault()}
    >
      <div className="flex flex-col">
        {links
          .filter(({ isHidden }) => !isHidden)
          .map((link) => {
            // isHidden needs to be removed from the DOM element
            const { isHidden, ...linkProps } = link;
            return link.component ? (
              <link.component
                key={link.id}
                label={link.label}
                setPopoverOpen={setPopoverOpen}
                className={linkClassName}
              />
            ) : (
              <Link
                key={link.id}
                as={link.link}
                appearance="secondary"
                size="sm"
                className={linkClassName}
                {...linkProps}
              >
                {link.label}
              </Link>
            );
          })}
      </div>
    </Popover>
  );
}
