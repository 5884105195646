import { useLocation, useNavigate, useSearchParams } from '@remix-run/react';
import { useCallback, useMemo } from 'react';
import type { HashValue } from '~/utils/tags';

export function useUrlParams() {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location]);

  function getAllSearchParamsByKey(key: string) {
    const params = searchParams.get(key);
    return params;
  }

  const getHashValueByKey = useCallback(
    (key: string) => {
      const hash = location.hash.slice(1);
      const hashParams = new URLSearchParams(hash);
      const params = hashParams.get(key);

      return params ?? undefined;
    },
    [location],
  );

  const getAllHashValuesByKey = useCallback(
    (key: string) => {
      const hash = location.hash.slice(1);
      const hashParams = new URLSearchParams(hash);
      const params = hashParams.getAll(key);

      return params.length ? params : undefined;
    },
    [location],
  );

  const getAllHashKeys = useCallback(() => {
    const hash = location.hash.slice(1);
    const hashParams = new URLSearchParams(hash);

    return Array.from(hashParams.keys());
  }, [location]);

  const hashValuesToArray = useCallback(() => {
    const hash = location.hash.slice(1);
    const hashParams = new URLSearchParams(hash);

    const hashValues: Array<HashValue> = [];

    hashParams.forEach((value, key) => {
      const existingEntry = hashValues.find((entry) => entry[key]);

      if (existingEntry) {
        existingEntry[key].push(value);
      } else {
        hashValues.push({ [key]: [value] });
      }
    });

    return hashValues.length ? hashValues : undefined;
  }, [location]);

  const setUrlParams = useCallback(
    (params = searchParams, hash = hashParams) => {
      const sanitizedParams = params ? params.toString() : searchParams.toString();
      const sanitizedHash = hash ? hash.toString() : undefined;

      navigate({
        search: sanitizedParams,
        hash: sanitizedHash,
      });
    },
    [hashParams, searchParams, navigate],
  );

  return {
    hashParams,
    location,
    searchParams,
    getAllHashKeys,
    getAllHashValuesByKey,
    getAllSearchParamsByKey,
    getHashValueByKey,
    hashValuesToArray,
    setSearchParams,
    setUrlParams,
  };
}
