import type { CheckedState } from '@radix-ui/react-checkbox';
import * as RadixSelect from '@radix-ui/react-select';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { contextFactory } from '~/utils/contextFactory';
import { classNames } from '~/utils/styles';
import { DropdownActions, DropdownTrigger } from './';
import type { DropdownContextProps, DropdownObjectItem, DropdownProps } from './';

export const getLabel = (item: DropdownObjectItem | string) => {
  return typeof item === 'string' ? item : item.label;
};

export const getValue = (item: DropdownObjectItem | string) => {
  return typeof item === 'string' ? item : item.value;
};

const contentClasses = classNames(
  'z-20 max-h-[450px] w-full bg-white px-0 shadow-md',
  'relative top-[-2px] flex flex-col items-start',
  'border border border-neutral-200',
);

export const [DropdownContext, useDropdownContext] = contextFactory<DropdownContextProps>();

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      applyBtnText = 'Apply',
      children,
      className,
      clearBtnText = 'Clear all',
      closeOnSelect = true,
      customTrigger,
      disabled,
      disabledItemTooltipText,
      dropdownOffset = 3,
      fixedLabel,
      groupBy,
      hasError,
      hideApplyButton = false,
      hideClearButton = false,
      id,
      indeterminateItems = [],
      isMultiple = false,
      isOpen = false,
      items = [],
      limit,
      noDataText = 'No results found',
      onEscapeKeydown,
      onOpenChange,
      onPointerDownOutside,
      onValueChange,
      placeholder,
      position = 'popper',
      selectAllText = () => 'Select all',
      selectedItems = [],
      triggerProps = {},
      unselectAllText = () => 'Unselect all',
    }: DropdownProps,
    forwardedRef,
  ) => {
    const triggerRef = useRef<HTMLDivElement | null>(null);

    const [openState, setOpenState] = useState(isOpen);
    const [searchValue, setSearchValue] = useState('');

    const [tempSelection, setTempSelection] = useState<string | string[]>(selectedItems);

    // used to restrict the number of selected items to the limit
    const [disableNonSelectedItems, setDisableNonSelectedItems] = useState(false);

    const handleOpenChange = (open: boolean) => {
      if (open) {
        if (limit) {
          setDisableNonSelectedItems(selectedItems.length >= limit);
        }
        setOpenState(open);
      }
      onOpenChange?.(open);
    };

    function getTriggerWidth() {
      return triggerRef.current?.getBoundingClientRect().width || 0;
    }

    function closeDropdown() {
      setTempSelection(isMultiple && hideApplyButton ? tempSelection : selectedItems);
      setOpenState(false);
      onOpenChange?.(false);
      setSearchValue('');
    }

    function handleApplyButtonClick() {
      onValueChange?.(tempSelection);
      setOpenState(false);
      onOpenChange?.(false);
      setSearchValue('');
    }

    function handleClearAllButtonClick() {
      setTempSelection([]);
      setDisableNonSelectedItems(false);
    }

    function handleEscapeKeydown(e: globalThis.KeyboardEvent) {
      onEscapeKeydown?.(e);
      closeDropdown();
    }

    function handlePointerDownOutside(e: CustomEvent<{ originalEvent: PointerEvent }>) {
      onPointerDownOutside?.(e);
      closeDropdown();
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
      setSearchValue(e.target.value);
    }

    function handleToggleSelectAll() {
      const values = items.map((item: DropdownObjectItem | string) => getValue(item));
      setTempSelection(areAllItemsSelected() ? [] : values);
    }

    const isItemSelected = useCallback(
      (item: DropdownObjectItem | string): CheckedState => {
        const isSelected = isMultiple
          ? (tempSelection as string[]).includes(getValue(item))
          : selectedItems === getValue(item);
        if (isSelected) {
          return isSelected;
        }

        const isIndeterminate = isMultiple ? indeterminateItems.includes(getValue(item)) : false;
        if (isIndeterminate) {
          return 'indeterminate';
        }

        return false;
      },
      [tempSelection, selectedItems, indeterminateItems, isMultiple],
    );

    function handleValueChange(newValues: string | string[]) {
      // when isMultiple is false, newValues is always a single string
      if (!isMultiple) {
        onValueChange?.(newValues);
        if (closeOnSelect) {
          setOpenState(false);
        }
        return;
      }

      // if has values unchecked, check all, otherwise uncheck all
      // this works either if newValues is from a group or single element selection
      const hasValuesUnchecked = (newValues as string[]).some((v) => !tempSelection.includes(v));

      const finalArray = hasValuesUnchecked
        ? [...new Set([...tempSelection, ...newValues])]
        : [...new Set([...tempSelection].filter((value) => value !== '' && !newValues.includes(value)))];

      if (limit) {
        setDisableNonSelectedItems(finalArray.length >= limit);
      }

      setTempSelection(finalArray);
      if (hideApplyButton) {
        onValueChange?.(finalArray);
      }
    }

    const getDisplayValue = useCallback(() => {
      if (Array.isArray(tempSelection)) {
        return (tempSelection as string[]).join(', ');
      }
      return selectedItems as string;
    }, [tempSelection, selectedItems]);

    const getDisplayLabelByValue = useCallback(
      (value = isMultiple ? tempSelection : selectedItems) => {
        if (isMultiple && Array.isArray(tempSelection)) {
          if (!value || !value.length) {
            return '';
          }
          return (tempSelection as string[])
            .map((value) => {
              const dropdownItem = items.find((i: DropdownObjectItem | string) => getValue(i) === value) || '';
              return getLabel(dropdownItem);
            })
            .join(', ');
        }

        if (!value) {
          return '';
        }

        const item = items.find((item: DropdownObjectItem | string) => getValue(item) === value) || '';
        return getLabel(item);
      },
      [tempSelection, isMultiple, items, selectedItems],
    );

    const areAllItemsSelected = useCallback((): CheckedState => {
      if (!isMultiple) {
        return false;
      }
      return (tempSelection as string[]).length === items.length;
    }, [tempSelection, isMultiple, items]);

    const filteredItems = useMemo(() => {
      return items.filter(
        (item: DropdownObjectItem | string) => getLabel(item).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
      );
    }, [searchValue, items]);

    const selectAllLabel = useMemo(() => {
      const selectedItemCount = items.length;
      const selectAllLabel = typeof selectAllText === 'function' ? selectAllText(selectedItemCount) : selectAllText;
      const unselectAllLabel =
        typeof unselectAllText === 'function' ? unselectAllText(selectedItemCount) : unselectAllText;
      return areAllItemsSelected() ? unselectAllLabel : selectAllLabel;
    }, [areAllItemsSelected, items, selectAllText, unselectAllText]);

    return (
      <div className={classNames(className)}>
        <RadixSelect.Root
          disabled={disabled}
          open={openState}
          value={getDisplayValue()}
          onOpenChange={handleOpenChange}
        >
          <DropdownTrigger
            id={id}
            ref={triggerRef}
            customTrigger={customTrigger}
            hasError={hasError}
            fixedLabel={fixedLabel}
            isDisabled={disabled}
            isOpen={openState}
            placeholder={placeholder}
            value={getDisplayLabelByValue()}
            {...triggerProps}
          />
          <RadixSelect.Portal>
            <DropdownContext.Provider
              value={{
                items: filteredItems,
                disabledItemTooltipText,
                disableNonSelectedItems,
                searchValue,
                groupBy,
                isMultiple,
                selectAllLabel,
                areAllItemsSelected,
                handleValueChange,
                handleToggleSelectAll,
                handleSearchChange,
                isItemSelected,
                getLabel,
                getValue,
                getTriggerWidth,
                closeDropdown,
              }}
            >
              <RadixSelect.Content
                asChild
                className={contentClasses}
                position={position}
                ref={forwardedRef}
                alignOffset={-1}
                sideOffset={dropdownOffset}
                onEscapeKeyDown={handleEscapeKeydown}
                onPointerDownOutside={handlePointerDownOutside}
              >
                <div>
                  <RadixSelect.Viewport style={{ width: getTriggerWidth(), scrollbarWidth: 'thin' }}>
                    {children}
                  </RadixSelect.Viewport>
                  {!filteredItems.length && (
                    <div className="label-100 p-4 text-center text-neutral-600">{noDataText}</div>
                  )}
                  {isMultiple && (
                    <DropdownActions
                      applyBtnText={applyBtnText}
                      clearBtnText={clearBtnText}
                      hideApplyButton={hideApplyButton}
                      hideClearButton={hideClearButton}
                      handleApplyButtonClick={handleApplyButtonClick}
                      handleClearAllButtonClick={handleClearAllButtonClick}
                    />
                  )}
                </div>
              </RadixSelect.Content>
            </DropdownContext.Provider>
          </RadixSelect.Portal>
        </RadixSelect.Root>
      </div>
    );
  },
);
