import { z } from 'zod';
import { bigNumberSchema } from '~/schemas/custom-schemas';

export const aggregateFunctions = z.enum(['sum', 'avg', 'min', 'max', 'mean']);
export const filterTypes = z.enum(['between', 'one_of', 'less_than_or_equal_to', 'greater_than']);
export const sortingOrders = z.enum(['ascending', 'descending']);
export const datetimeBinEnum = z.enum([
  'five_min_of_hour',
  'fifteen_min_of_hour',
  'thirty_min_of_hour',
  'hour',
  'day',
  'day_of_week',
  'month',
  'quarter',
  'year',
  'year_uk_compliance_period',
]);

export type SortingOrder = z.infer<typeof sortingOrders>;
export type DateTimeBin = z.infer<typeof datetimeBinEnum>;

// Filter schemas
const periodStartFilter = z.object({
  type: filterTypes,
  from: z.string().datetime(), // Inclusive
  to: z.string().datetime(), // Exclusive
});

const stringArrayFilter = z.object({
  type: filterTypes,
  items: z.array(z.string().nullable()),
});

const stringValueFilter = z.object({
  type: filterTypes,
  value: z.string(),
});

// Pagination schema
const pagination = z.object({
  skip: z.number().int().positive().optional(),
  limit: z.number().int().positive().optional(),
});

// Create request's and response's schemas functions
export function createMetricsTableRequest<
  T extends z.ZodUnion<readonly [z.ZodTypeAny, ...z.ZodTypeAny[]]>,
  U extends z.ZodUnion<readonly [z.ZodTypeAny, ...z.ZodTypeAny[]]>,
>(columnsUnion: T, labelUnion: U) {
  return z.object({
    views: z.array(
      z.object({
        label: labelUnion,
        pagination: pagination.optional(),
        groups: z
          .array(
            z.object({
              column: columnsUnion,
              bin: datetimeBinEnum.optional(),
              limit: z.number().optional(),
            }),
          )
          .optional(),
        metrics: z.array(
          z.object({
            column: columnsUnion,
            aggregation_function: aggregateFunctions,
          }),
        ),
        paging: z
          .object({
            from: z.number(),
            to: z.number(),
          })
          .optional(),
        sort: z
          .array(
            z.object({
              column: columnsUnion,
              bin: datetimeBinEnum.optional(),
              order: sortingOrders,
            }),
          )
          .optional(),
      }),
    ),
  });
}

export function createMetricsTableResponse<
  T extends z.ZodUnion<readonly [z.ZodTypeAny, ...z.ZodTypeAny[]]>,
  U extends z.ZodUnion<readonly [z.ZodTypeAny, ...z.ZodTypeAny[]]>,
>(columnsUnion: T, labelUnion: U) {
  return z.object({
    views: z.array(
      z.object({
        label: labelUnion,
        columns: z.array(z.object({ column: columnsUnion, bin: datetimeBinEnum.optional() })),
        data: z.array(z.array(z.union([bigNumberSchema as z.ZodType<Big>, z.string(), z.number()]))),
      }),
    ),
  });
}

// Supply table schemas
const supplyTableLabel = z.union([z.literal('kpiTotals'), z.literal('matchingOverTime'), z.literal('matchingAverage')]);
const supplyColumns = z.union([
  z.literal('period_start'),
  z.literal('supply'),
  z.literal('supply_allocated'),
  z.literal('supply_unallocated'),
]);

export type SupplyColumn = z.infer<typeof supplyColumns>;

const supplyTableRequest = createMetricsTableRequest(supplyColumns, supplyTableLabel);
const supplyTableResponse = createMetricsTableResponse(supplyColumns, supplyTableLabel);

// Allocated supply schemas
const allocatedSupplyTableLabel = z.union([
  z.literal('matchingOverTimeByAllocatedConsumer'),
  z.literal('totalsByAllocatedConsumer'),
  z.literal('matchingAverageByAllocatedConsumer'),
]);
const allocatedSupplyColumns = z.union([
  z.literal('period_start'),
  z.literal('allocated_consumer_client_reference'),
  z.literal('allocated_consumer_name'),
  z.literal('supply_allocated'),
]);
export type AllocatedSupplyColumn = z.infer<typeof allocatedSupplyColumns>;

const allocatedSupplyTableRequest = createMetricsTableRequest(allocatedSupplyColumns, allocatedSupplyTableLabel);
const allocatedSupplyTableResponse = createMetricsTableResponse(allocatedSupplyColumns, allocatedSupplyTableLabel);

export type SupplyView = z.infer<typeof supplyTableResponse>['views'][number];
export type AllocatedSupplyView = z.infer<typeof allocatedSupplyTableResponse>['views'][number];

// POST /metrics/supply

// TODO: create a function to create the schema for the requests and responses
// These will then need to be passed into the query hooks to validate the responses

export const supplyFilters = z.object({
  period_start: periodStartFilter,
  production_device_client_reference: stringArrayFilter.optional(),
  production_device_technology: stringArrayFilter.optional(),
  production_device_country: stringArrayFilter.optional(),
  production_device_subsidy_regime: stringArrayFilter.optional(),
  production_device_registry_identifier: stringArrayFilter.optional(),
  production_device_capacity: stringValueFilter.optional(),
  supply_duration: stringValueFilter.optional(),
});

// Supply-view request & response
export const supplyRequestSchema = z.object({
  filters: supplyFilters,
  tables: z.object({
    supply: supplyTableRequest,
    allocated_supply: allocatedSupplyTableRequest,
  }),
});

export const supplyResponseSchema = z.object({
  tables: z.object({
    supply: supplyTableResponse,
    allocated_supply: allocatedSupplyTableResponse,
  }),
});

export type SupplyRequest = z.infer<typeof supplyRequestSchema>;
export type SupplyResponse = z.infer<typeof supplyResponseSchema>;

// Plotting resolution request & response
export const supplyPlottingResolutionsRequestSchema = z.object({
  filters: supplyFilters,
});

export const supplyPlottingResolutionsResponseSchema = z.object({
  plotting_resolutions: z.array(datetimeBinEnum),
});

export type SupplyPlottingResolutionsRequest = z.infer<typeof supplyPlottingResolutionsRequestSchema>;
export type SupplyPlottingResolutionsResponse = z.infer<typeof supplyPlottingResolutionsResponseSchema>;
