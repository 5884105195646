import Big from 'big.js';
import { z } from 'zod';

export class Money {
  amount: Big;
  currency: string;

  constructor(amount: string | number | Big, currency: string) {
    this.amount = new Big(amount);
    this.currency = currency.toUpperCase();
  }

  static fromString(value: string) {
    const [amount, currency] = value.split(' ');
    return new Money(amount, currency);
  }
}

// Zod schema for Money
export const moneySchema = z.custom<Money>((val) => {
  if (typeof val === 'string') {
    try {
      Money.fromString(val);
      return true;
    } catch {
      return false;
    }
  }
  return val instanceof Money;
}, 'Invalid money format. Expected format: "100.00 USD"');

export type MoneySchema = z.infer<typeof moneySchema>;
