import * as RadixSelect from '@radix-ui/react-select';

import { Button, ButtonActions, Divider } from '~/components/granular';
import { useDropdownContext } from '../..';

import type { DropdownActionsProps } from './DropdownActions.types';

export function DropdownActions({
  applyBtnText,
  clearBtnText,
  customBtnActions = [],
  hideApplyButton,
  hideClearButton,
  handleApplyButtonClick,
  handleClearAllButtonClick,
}: DropdownActionsProps) {
  const hasCustomBtnActions = customBtnActions.length > 0;
  const shouldDisplayBtnsBlock = !hideApplyButton || !hideClearButton || hasCustomBtnActions;

  const { closeDropdown } = useDropdownContext();

  return (
    <RadixSelect.Group className="w-full">
      {/* hide whole block only if both buttons have to be hidden or if going with custom actions */}
      {shouldDisplayBtnsBlock && (
        <>
          <Divider className="w-full" />

          <div className="flex w-full flex-column justify-between px-2 py-2">
            {!hideClearButton && !hasCustomBtnActions && (
              <Button
                appearance="ghost"
                size="small"
                className="!justify-center mx-2 w-full"
                onClick={handleClearAllButtonClick}
              >
                {clearBtnText}
              </Button>
            )}

            {!hideApplyButton && !hasCustomBtnActions && (
              <Button
                appearance="default"
                size="small"
                className="!justify-center mx-2 w-full"
                onClick={handleApplyButtonClick}
              >
                {applyBtnText}
              </Button>
            )}

            {customBtnActions.length > 0 && (
              <ButtonActions
                actions={customBtnActions.map((action) => ({
                  ...action,
                  onClick: () => {
                    if (action?.closeDropdownOnClick) {
                      closeDropdown();
                    }
                    action?.onClick?.();
                  },
                }))}
                className="[&>button]:!justify-center w-full [&>button]:w-full"
              />
            )}
          </div>
        </>
      )}
    </RadixSelect.Group>
  );
}
