import { useEffect, useMemo, useState } from 'react';
import { Checkbox, type SelectGroup, type SelectItem, useBaseSelectContext } from '~/components/granular';
import { Loading } from '../loading';
import { NoData } from '../no-data';
import { SearchInput } from '../search-input';
import { SelectListApplyButtons } from '../select-list-apply-buttons';
import { SelectOption } from '../select-option';
import { VirtualisedSelectList } from '../virtualised-select-list';

export function CheckboxList() {
  const { indeterminateItems, items, loading, selectedItems, selectedLimit, handleSelectChange, resetSearchTerm } =
    useBaseSelectContext();

  const [tempChildSelection, setTempChildSelection] = useState<Array<SelectItem>>([]);

  useEffect(() => {
    setTempChildSelection(selectedItems);
  }, [selectedItems]);

  function handleTempChildSelection(item: SelectItem | SelectGroup) {
    if (tempChildSelection.find((selectedItem) => selectedItem.value === item.value)) {
      setTempChildSelection((prev) => prev.filter((prevItem) => prevItem.value !== item.value));
    } else {
      setTempChildSelection((prev) => [...prev, item]);
    }
  }

  function handleApplySelection() {
    if (handleSelectChange) {
      handleSelectChange(tempChildSelection);
    }

    resetSearchTerm();
  }

  function handleResetSelection() {
    setTempChildSelection([]);
    if (handleSelectChange) {
      handleSelectChange([]);
    }

    resetSearchTerm();
  }

  const isItemSelected = (item: SelectItem) => {
    if (tempChildSelection.some((selectedItem) => selectedItem.value === item.value)) {
      return true;
    }
    if (indeterminateItems?.some((i) => i.value === item.value)) {
      return 'indeterminate';
    }
    return false;
  };

  function handleSelectAll() {
    if (tempChildSelection.length < items.length) {
      setTempChildSelection(items);
    } else {
      setTempChildSelection([]);
    }
  }

  const isItemSelectedLimitReached = useMemo(() => {
    const selectedItemsCount = tempChildSelection.length;
    return selectedLimit !== undefined && selectedItemsCount >= selectedLimit;
  }, [selectedLimit, tempChildSelection]);

  const displaySelectAll = useMemo(() => !!selectedLimit && items.length <= selectedLimit, [selectedLimit, items]);

  return (
    <>
      <SearchInput
        withSelectAll={displaySelectAll}
        onSelectAll={handleSelectAll}
        isChecked={!!items.length && tempChildSelection.length >= items.length}
        itemsCount={items.length}
      />

      {loading && <Loading />}

      {!loading && !items.length && <NoData />}

      <VirtualisedSelectList>
        {({ data, style, index }) => (
          <SelectOption
            style={style}
            item={data[index]}
            isSelected={isItemSelected(data[index])}
            onItemClick={handleTempChildSelection}
            disabled={isItemSelectedLimitReached && !isItemSelected(data[index])}
          >
            <Checkbox
              id={data[index].value}
              className="overflow-hidden whitespace-nowrap"
              name={data[index].value}
              checked={isItemSelected(data[index])}
              label={data[index].label}
              disabled={isItemSelectedLimitReached && !isItemSelected(data[index])}
            />
          </SelectOption>
        )}
      </VirtualisedSelectList>

      <SelectListApplyButtons onApplySelection={handleApplySelection} onResetSelection={handleResetSelection} />
    </>
  );
}
