import { forwardRef, useCallback, useMemo, useState } from 'react';
import { type SelectGroup, type SelectItem, useBaseSelectContext } from '~/components/granular';
import { useFilterHandlers } from '~/hooks/combo-filter/useFilterHandlers';
import { BackButton } from '../components/back-button';
import { Loading } from '../components/loading';
import { NoData } from '../components/no-data';
import { SearchInput } from '../components/search-input';
import { SelectOption } from '../components/select-option';
import { VirtualisedSelectList } from '../components/virtualised-select-list';
import { DropdownSelect } from '../dropdown-select/DropdownSelect';
import { ListSelect } from '../list-select';
import type { GroupSelectFilterProps, GroupSelectInputProps, GroupedSelectProps } from './GroupedSelect.props';

/* -------------------------------------------------------------------------------------------------
 * GroupedSelect
 * -----------------------------------------------------------------------------------------------*/

export const GroupedSelect = forwardRef<HTMLDivElement, GroupedSelectProps>(
  (
    {
      appearance = 'filter',
      applyLabel,
      backLabel,
      disableFiltering,
      disabled,
      indeterminateItems,
      items = [],
      label,
      leftIcon,
      loading,
      noDataLabel,
      placeholder,
      searchPlaceholder,
      selectedItems = [],
      selectedLimit,
      size = 'small',
      width,
      trigger,
      onGroupSelection,
      onSelectChange,
    },
    forwardedRef,
  ) => {
    return (
      <DropdownSelect
        ref={forwardedRef}
        appearance={appearance}
        applyLabel={applyLabel}
        backLabel={backLabel}
        disableFiltering={disableFiltering}
        disabled={disabled}
        indeterminateItems={indeterminateItems}
        items={items}
        label={label}
        leftIcon={leftIcon}
        loading={loading}
        noDataLabel={noDataLabel}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        selectedLimit={selectedLimit}
        size={size}
        trigger={trigger}
        width={width}
        onSelectChange={onSelectChange}
      >
        <GroupedSelectGroups onGroupSelection={onGroupSelection} />
      </DropdownSelect>
    );
  },
);

/* -------------------------------------------------------------------------------------------------
 * GroupedSelectGroups
 * -----------------------------------------------------------------------------------------------*/

function GroupedSelectGroups({ onGroupSelection }: { onGroupSelection?: (group: SelectGroup | null) => void }) {
  const { loading, handleSearchChange, handleSelectChange, toggleOpen } = useBaseSelectContext();

  const [activeGroup, setActiveGroup] = useState<SelectGroup | null>(null);

  function handleGroupClick(item: SelectItem | SelectGroup) {
    if ('children' in item) {
      handleSearchChange();
      setActiveGroup(item);
      onGroupSelection?.(item);
    }
  }

  function handleBackButtonClick() {
    setActiveGroup(null);
  }

  const childFilter = useMemo(() => {
    return activeGroup?.children({ toggleOpen, handleSelectChange });
  }, [activeGroup, toggleOpen, handleSelectChange]);

  if (loading) {
    return <Loading />;
  }

  return !activeGroup ? (
    <GroupSelectParentOptions onItemClick={handleGroupClick} />
  ) : (
    <>
      <BackButton visible onClick={handleBackButtonClick} />
      {childFilter}
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * GroupSelectParentOptions
 * -----------------------------------------------------------------------------------------------*/

function GroupSelectParentOptions({ onItemClick }: { onItemClick: (item: SelectItem | SelectGroup) => void }) {
  const { items } = useBaseSelectContext();

  return (
    <>
      <SearchInput />

      {!items.length && <NoData />}

      <VirtualisedSelectList>
        {({ data, style, index }) => (
          <SelectOption style={style} item={data[index]} onItemClick={onItemClick}>
            {data[index].label}
          </SelectOption>
        )}
      </VirtualisedSelectList>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * GroupSelectFilter
 * -----------------------------------------------------------------------------------------------*/

function selectedItemsMapper(selectedGroups: Array<string>, items: Array<SelectItem>) {
  if (!items) {
    return [];
  }

  const selectedFilters = items.filter((item) => selectedGroups.includes(item.value));
  return selectedFilters.map((item) => ({
    label: item.label,
    value: item.value,
  }));
}

export function GroupSelectFilter({ filterKey, toggleOpen, items, isLoading }: GroupSelectFilterProps) {
  const mapSelectedItems = useCallback(
    (selectedGroups: Array<string>) => selectedItemsMapper(selectedGroups, items),
    [items],
  );

  const { selectedTagsToUrlHash, selectedFilters } = useFilterHandlers<Array<SelectItem>>({
    filterKey,
    isLoading,
    mapSelectedItems,
  });

  return (
    <ListSelect
      toggleOpen={toggleOpen}
      loading={isLoading}
      onSelectChange={(items) => selectedTagsToUrlHash(filterKey, items)}
      selectedItems={selectedFilters}
      items={items}
      selectedLimit={1000}
    />
  );
}

/* -------------------------------------------------------------------------------------------------
 * GroupSelectInput
 * -----------------------------------------------------------------------------------------------*/

export function GroupSelectInput({ toggleOpen, items, isLoading, onSelectChange }: GroupSelectInputProps) {
  return (
    <ListSelect
      toggleOpen={toggleOpen}
      loading={isLoading}
      onSelectChange={onSelectChange}
      selectedItems={[]}
      items={items}
      selectedLimit={1000}
    />
  );
}
