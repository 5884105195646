export const routes = {
  allocations: {
    cancelFinalizationRequest: (finalizationRequestId: string) =>
      `/allocations/finalization-requests/${finalizationRequestId}`,
    canFinalizeAllocation: (allocationEventId: string) => `/allocations/events/${allocationEventId}/can-finalize`,
    confirmAllocationDraft: (allocationEventId: string) => `/allocations/events/${allocationEventId}/confirm`,
    createAllocationDraft: '/allocations/events/draft',
    deleteAllocation: (id: string) => `/allocations/events/${id}`,
    downloadAllocationEvents: (allocationEventId: string) => `/allocations/events/${allocationEventId}/download`,
    downloadCancellationInstructions: (finalizationRequestId: string) =>
      `/allocations/finalization-requests/${finalizationRequestId}/download`,
    legacy__finalizeAllocation: (id: string) => `/allocations/events/${id}/finalize`,
    generateAllocationInstructions: '/allocations/finalization-requests',
    getAllocationEvents: '/allocations/events',
    getAvailableRegistryAccounts: (allocationEventId: string) =>
      `/allocations/events/${allocationEventId}/available-registry-accounts`,
    getAvailableVolumes: (allocationEventId: string) => `/allocations/events/${allocationEventId}/available-volumes`,
    getRedeemedCertificateBundles: (id: string) => `/allocations/events/${id}/redeemed-certificate-bundles`,
    removeAllocationDraftConsumer: (allocationEventId: string, consumerId: string) =>
      `/allocations/events/${allocationEventId}/consumers/${consumerId}`,
    updateConsumptionAllocationDraft: (allocationEventId: string) =>
      `/allocations/events/${allocationEventId}/consumption-driven/allocate`,
    updateProductionAllocationDraft: (allocationEventId: string, consumerId: string) =>
      `/allocations/events/${allocationEventId}/allocate/${consumerId}`,
  },
  consumers: {
    createConsumer: '/consumers',
    deleteConsumer: (id: string) => `/consumers/${id}`,
    getConsumer: (id: string) => `/consumers/${id}`,
    getConsumerMeteringPointStates: (id: string) => `/consumers/${id}/metering-point-states`,
    getConsumerMeteringPoints: (id: string) => `/consumers/${id}/metering-points`,
    getConsumers: '/consumers',
    updateConsumer: (id: string) => `/consumers/${id}`,
  },
  deals: {
    getDeal: (id: string) => `/deals/${id}`,
    getDeals: '/deals',
    getDealOptions: '/deals',
  },
  reconciliation: {
    getReconciliation: '/certificate-bundle/reconciliation',
  },
  endConsumers: {
    downloadConsumerReport: (id: string) => `/endconsumers/reports/${id}/download`,
  },
  import: {
    getUploadedConsumptionFileStatus: (id: string) => `/multipoint-consumption-files/${id}`,
    getUploadedProductionFileStatus: (id: string) => `/multipoint-production-files/${id}`,
    uploadConsumptionFile: '/multipoint-consumption-files/upload',
    uploadProductionFile: '/multipoint-production-files/upload',
  },
  meteringPoints: {
    createMeteringPoint: '/metering-points',
    getMeteringPoints: '/metering-points',
    updateMeteringPoint: (id: string) => `/metering-points/${id}`,
  },
  metrics: {
    getConsumerCarbon: '/metrics/consumer/carbon',
    getConsumerLatest: '/metrics/consumer/latest',
    getConsumerOverview: '/metrics/consumer/overview',
    getConsumerMatchingResolutions: '/metrics/consumer/matching-resolutions',
    getPerimeter: '/metrics/perimeter',
    getPortfolioOverview: '/metrics/portfolio/overview',
    getSupply: '/metrics/supply',
    getSupplyLatest: '/metrics/supply/latest',
    getSupplyPlottingResolutions: '/metrics/supply/plotting-resolutions',
  },
  certificates: {
    getCertificateBalances: '/certificate-bundle/balances',
    getCertificateBalancesChunked: '/certificate-bundle/balances/chunked',
    getHeldCertificates: '/certificate-bundle/holding',
    legacy_uploadCertificates: '/certificate-bundle/upload-certificates',
    uploadCertificates: '/certificate-bundle/upload',
    getCertificateUploadStatus: (id: string) => `/certificate-bundle/files/${id}`,
    uploadOfgemCertificates: '/certificate-bundle/upload-ofgem-certificates',
  },
  // TODO: will be removed after removing inventory feature flag
  certificateBundles: {
    getCertificateBundles: '/certificate-bundle',
  },
  // ---------------------
  ppas: {
    createPpa: '/ppas',
    deletePpa: (id: string) => `/ppas/${id}`,
    getPpa: (id: string) => `/ppas/${id}`,
    getPpas: '/ppas',
  },
  portfolio: {
    getDemandPortfolio: '/portfolio/demand/download',
    getDemandPortfolioStatus: (id: string) => `/portfolio/demand/upload/${id}`,
    putDemandUpload: '/portfolio/demand/upload',
    putSupplyUpload: '/portfolio/supply/upload',
  },
  productionDevices: {
    createProductionDevice: '/assets',
    deleteProductionDevice: (id: string) => `/assets/${id}`,
    getProductionDevice: (id: string) => `/assets/${id}`,
    getProductionDevicesWithMeteringPoints: '/production-devices-with-metering-points',
    getProductionDevices: '/production-devices',
    updateProductionDevice: (id: string) => `/assets/${id}`,
  },
  registryTransactions: {
    getRegistryTransactions: '/registry-transactions',
    uploadRegistryTransactionsFile: '/registry-transactions/upload',
    getRegistryTransactionsStatus: (id: string) => `/registry-transactions/files/${id}`,
    getRegistryTransactionDetails: (id: string) => `/registry-transactions/${id}/certificate-bundles`,
  },
  reports: {
    deleteReport: (id: string) => `/reports/${id}`,
    downloadReport: (id: string) => `/reports/${id}/download`,
    generateReport: '/reports/bulk',
    getFilterLevel: '/reports/filter-values/level',
    getFilterScope: '/reports/filter-values/scope',
    getReports: '/reports',
  },
  risk: {
    getMetrics: '/risk/metrics',
  },
  tags: {
    getFilterableTags: '/tags/consumption/filterable',
    getTagValues: (key: string) => `/tags/consumption/${key}/values`,
    getTags: '/tags/consumption',
  },
  termsAndConditions: {
    acceptTermsAndConditions: (id: string, userEmail: string) =>
      `/terms-and-conditions/${id}/users/${userEmail}/accept`,
    downloadTermsAndConditions: (id: string) => `/terms-and-conditions/${id}/download`,
    getTermsAndConditions: '/terms-and-conditions',
    getTermsAndConditionsUserAcceptance: (userEmail: string) => `/terms-and-conditions/users/${userEmail}/check`,
  },
  userConfig: {
    getUserConfig: '/user-config',
  },
} as const;

export type Route = (typeof routes)[keyof typeof routes];
