export const colors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  neutral: {
    '100': '#FBFAF9',
    '200': '#F2EFED',
    '300': '#E3E0DD',
    '400': '#D2CECB',
    '500': '#B8B2AD',
    '600': '#897F78',
    '700': '#6C6560',
    '800': '#56514E',
    '900': '#3D3A38',
    '1000': '#2A2929',
  },
  teal: {
    '100': '#F2F9F8',
    '200': '#E8F4F3',
    '500': '#94D1CC',
    '700': '#00988B',
    '800': '#03857A',
    '900': '#06756C',
  },
  green: {
    '100': '#F1F9F6',
    '700': '#08875D',
    '800': '#04724D',
    '900': '#066042',
  },
  yellow: {
    '100': '#FFF8EB',
    '700': '#B25E09',
    '800': '#96530F',
    '900': '#80460D',
  },
  red: {
    '100': '#FFF6F7',
    '200': '#FAEBEC',
    '700': '#E02D3C',
    '800': '#BA2532',
    '900': '#981B25',
  },
  gradient: {
    '100': '#006582',
    '200': '#007F8A',
    '300': '#00988B',
    '400': '#1FB084',
    '500': '#5AC276',
    '600': '#92CF64',
    '700': '#C9D753',
    '800': '#FFDB4C',
    '9000': '#F3A45B',
    '1000': '#A4776C',
    '1100': '#8195A9',
  },
  technology: {
    anyRenewable: '#8195A9',
    battery: '#CBC0E9',
    biodegradable: '#B9E198',
    biogas: '#F6AFAF',
    biomass: '#57B06B',
    coal: '#BAD9F6',
    gas: '#E86571',
    geoThermal: '#F3A45B',
    hydro: '#6EA1DD',
    landfillGas: '#F398CD',
    marine: '#BAD9F6',
    nuclear: '#AB80CC',
    offshoreWind: '#A6E5CF',
    onshoreWind: '#58CAA1',
    sewageGas: '#FBCEE6',
    solar: '#FDCF77',
    unknown: '#B3BFC9',
    wind: '#81D8B9',
  },
  chart: {
    long: '#1FB084',
    short: '#0F4974',
    crosshair: '#0f4974',
    production: '#92cf64',
    excesses: '#1fb084',
    shortfalls: '#d2cecb',
    total: '#0f4974',
    totalMarker: '#0f4974',
    matched: '#007f8a',
    ready: '#007f8a',
    pending: '#73B3FF',
    unmatched: '#A4A4A4',
    stripedBackground: '#CAE8DF',
    sankey: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0, '#92cf64'],
        [0.5, '#007f8a'],
      ],
    },
  },
} as const;

export const pattern = {
  stroke45Deg: 'M 0 8 L 8 0 M -0.8 0.8 L 0.8 -0.8 M 7.2 8.8 L 8.8 7.2', // Size 8
  size: 8,
  strokeWidth: 3,
} as const;
