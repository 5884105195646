import { z } from 'zod';
import { countryEnum, technologyEnum } from './enums';
import { moneySchema } from './money';

import type { ChipProps } from '~/components/granular';

export type DealsViewType = 'per-deal' | 'per-delivery-dates';

export const certificateLabelEnum = z.enum([
  'Green-E',
  'TUV-Nord',
  'TUV-EE',
  'TUV-EE+',
  'TUV-EE-01',
  'TUV-EE-02',
  'EKOenergy',
  'OK-power',
  'Naturemade_Star',
]);

export const dealSideEnum = z.enum(['BUY', 'SELL']);

export const dealStatusEnum = z.enum(['OPEN', 'PART_SETTLED', 'SETTLED']);
export const dealTrancheStatusEnum = z.enum(['OPEN', 'PART_SETTLED', 'SETTLED']);
export const dealTrancheVolumeStatusEnum = z.enum(['OPEN', 'SETTLED']);
export type DealStatus = z.infer<typeof dealStatusEnum>;
export type DealTrancheStatus = z.infer<typeof dealTrancheStatusEnum>;
export type DealTrancheVolumeStatus = z.infer<typeof dealTrancheVolumeStatusEnum>;
export const DEAL_TYPE_COLORS: {
  [key in DealStatus]: ChipProps['appearance'];
} = {
  SETTLED: 'success',
  PART_SETTLED: 'warning',
  OPEN: 'info',
} as const;

export const registryTransactionTypeEnum = z.enum(['CANCEL', 'TRANSFER']);

export const subNationalRegionEnum = z.enum(['England', 'Wales', 'Scotland', 'Northern Ireland']);

export const supraNationalRegionEnum = z.enum(['AIB', 'AIB Grid Connected']);

export const trancheSchema = z.object({
  id: z.string().uuid(),
  delivery_date: z.string().date(),
  price_per_mwh: moneySchema,
  status: dealTrancheStatusEnum,
  volumes: z.array(
    z.object({
      id: z.string().uuid(),
      vintage_start: z.string().date(),
      vintage_end: z.string().date(),
      volume: z.string(),
      status: dealTrancheVolumeStatusEnum,
      created_at: z.string().datetime(),
      updated_at: z.string().datetime(),
    }),
  ),
  created_at: z.string(),
  updated_at: z.string(),
});
export type Tranche = z.infer<typeof trancheSchema>;

export const dealSchema = z.object({
  id: z.string().uuid(),
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  client_reference: z.string().nullable(),
  side: dealSideEnum,
  counterparty_id: z.string().uuid(),
  counterparty_name: z.string(),
  executed_at: z.string().datetime(),
  currency: z.string(),
  registry_transaction_type: registryTransactionTypeEnum,
  registry_account: z.string().uuid().nullable(),
  registry_account_holder: z.string().uuid().nullable(),
  status: dealStatusEnum,
  requirement_subsidy_regime: z.boolean().nullable(),
  requirement_max_asset_age: z.number().nullable(),
  requirement_minimum_commercial_operating_date: z.string().date().nullable(),
  requirement_country: z.array(countryEnum).nullable(),
  requirement_sub_national_region: z.array(subNationalRegionEnum).nullable(),
  requirement_supra_national_region: z.array(supraNationalRegionEnum).nullable(),
  requirement_technology: z.array(technologyEnum).nullable(),
  requirement_certificate_label: z.array(certificateLabelEnum).nullable(),
  requirement_certificate_type: z.string(),
  tranches: z.array(trancheSchema),
  total_volume: z.string(),
  average_price_per_mwh: moneySchema,
  total_deal_value: moneySchema,
});
export type Deal = z.infer<typeof dealSchema>;

export const dealListSchema = z.array(dealSchema);
export type DealList = z.infer<typeof dealListSchema>;

// flatmap schema between deal and tranches: this is what the grid will display
export const dealTrancheSchema = dealSchema
  .omit({ tranches: true })
  .merge(trancheSchema)
  .merge(
    z.object({
      deal_type: z.literal('firm'),
      generation_period_start: z.string().date().nullable(),
      generation_period_end: z.string().date().nullable(),
    }),
  );
export type DealTranche = z.infer<typeof dealTrancheSchema>;

export const dealFormSchema = z.object({
  counterparty_id: z.string().uuid(),
  deal_id: z.string(),
  executed_at: z.string().datetime(),
  side: dealSideEnum,
  // requirements
  requirement_subsidy_regime: z.boolean().optional(),
  requirement_max_asset_age: z.number().int().positive().optional(),
  requirement_minimum_commercial_operating_date: z.string().date().optional(),
  requirement_country: z.array(countryEnum).optional(),
  requirement_sub_national_region: z.array(subNationalRegionEnum).optional(),
  requirement_supra_national_region: z.array(supraNationalRegionEnum).optional(),
  requirement_technology: z.array(technologyEnum).optional(),
  requirement_certificate_label: z.array(certificateLabelEnum).optional(),
});
export type DealForm = z.infer<typeof dealFormSchema>;

export const dealOptionsSchema = z.object({
  deal_options: z.object({
    currency: z.array(z.string()),
    registry_transaction_type: z.array(z.string()),
    requirement_certificate_type: z.array(z.string()),
    requirement_certificate_label: z.array(z.string()),
    requirement_country: z.array(countryEnum),
    requirement_sub_national_region: z.array(z.string()),
    requirement_supra_national_region: z.array(z.string()),
    requirement_technology: z.array(technologyEnum),
    side: z.array(dealSideEnum),
    status: z.array(dealStatusEnum),
  }),
  deal_tranche_options: z.object({
    status: z.array(dealTrancheStatusEnum),
  }),
  deal_tranche_volume_options: z.object({
    status: z.array(dealTrancheVolumeStatusEnum),
  }),
});
export type DealOptions = z.infer<typeof dealOptionsSchema>;

export const commercialOperationDateConstraint = z.enum(['BEFORE', 'ON_OR_AFTER']);

export const deliverableTypes = [
  'requirement_commercial_operation_date',
  'requirement_subsidy_regime',
  'requirement_country',
  'requirement_technology',
  'requirement_certificate_label',
  'requirement_supra_national_region',
  'requirement_sub_national_region',
  'requirement_certificate_type',
];
